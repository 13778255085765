/* eslint-disable no-underscore-dangle */
/* eslint-disable no-shadow */
/* eslint-disable no-unneeded-ternary */
import * as Realm from 'realm-web';
import envConfig from '../../env/env.json';

export const app = new Realm.App({ id: envConfig.MONGODB_APP_ID });
export const gApp = new Realm.App({ id: envConfig.MONGODB_APP_ID_GENERAL });
export const middlewareApp = new Realm.App({ id: envConfig.MONGODB_APP_ID_MIDDLEWARE });
export const {
  BSON: { ObjectId },
} = Realm;
export const getDBInstance = () => {
  if (!app || !app.currentUser) {
    return;
  }
  return app.currentUser.mongoClient('mongodb-atlas').db(envConfig.MONGODB_DB);
};

export const generalLogin = async () => {
  const gUser = await gApp.logIn(Realm.Credentials.apiKey(envConfig.MONGODB_APP_TOKEN_GENERAL));
  return gUser;
};

export const middlewareLogin = async () => {
  const gUser = await middlewareApp.logIn(Realm.Credentials.apiKey(envConfig.MONGODB_APP_TOKEN_MIDDLEWARE));
  return gUser;
};

export const generateSlug = ({ title }) => {
  // Convert to lowercase
  return (
    title
      // Convert to lowercase
      .toLowerCase()
      // Replace spaces with hyphens
      .replace(/\s+/g, '-')
      // Remove all non-word chars (keep only letters, numbers, and hyphens)
      .replace(/[^a-z0-9-]/g, '')
      // Replace multiple hyphens with a single hyphen
      .replace(/-+/g, '-')
  );
};

export const fetchUserCount = async () => {
  const db = getDBInstance();
  if (!db) {
    throw new Error('Database instance is not available');
  }
  const usersCollection = db.collection('customers');
  const userCount = await usersCollection.count({});
  return userCount;
};

export const fetchNewsCount = async () => {
  if (!app || !app.currentUser) {
    throw new Error('Must be logged in to fetch data');
  }

  const totalNewsCount = await getDBInstance().collection('news').count({});
  return totalNewsCount;
};

export const fetchTodayNewsCount = async () => {
  if (!app || !app.currentUser) {
    throw new Error('Must be logged in to fetch data');
  }

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  const todayNewsCount = await getDBInstance()
    .collection('news')
    .count({
      dateCreated: { $gte: today, $lt: tomorrow },
    });
  return todayNewsCount;
};

export const getCurrentUser = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('users')
    .aggregate([
      {
        $match: {
          _id: id,
        },
      },
      {
        $lookup: {
          from: 'userRoles',
          localField: 'roleId',
          foreignField: '_id',
          as: 'role',
        },
      },
      { $unwind: '$role' },
      {
        $project: {
          email: 1,
          firstName: 1,
          lastName: 1,
          number: 1,
          createdAt: 1,
          updatedAt: 1,
          isVerified: 1,
          roleId: 1,
          role: 1,
          permissions: '$role.permissions',
        },
      },
    ]);

  return result[0];
};

export const DeleteRole = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('userRoles').deleteOne({ _id: id });

  return result;
};

export const AddRole = async ({ data, permissions }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  const existingRole = await getDBInstance()
    .collection('userRoles')
    .findOne({
      name: { $regex: new RegExp(`^${data.name}$`, 'i') },
    });
  if (existingRole) {
    return false;
  }
  const result = await getDBInstance().collection('userRoles').insertOne({
    name: data.name,
    permissions,
  });
  return result;
};

export const UpdateRole = async ({ id, data, permissions }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('userRoles')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          name: data.name,
          permissions,
        },
      },
    );

  return result;
};

export const getRoles = async ({ search }) => {
  if (!app || !app.currentUser) {
    return;
  }

  let query = {};

  if (search) {
    query = {
      $or: [{ name: { $regex: search, $options: 'i' } }],
    };
  }

  const result = await getDBInstance()
    .collection('userRoles')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getOneRole = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('userRoles')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getRegisterRoles = async () => {
  if (!app || !app.currentUser) {
    const credentials = Realm.Credentials.anonymous();
    await app.logIn(credentials);
  }

  const result = await getDBInstance()
    .collection('userRoles')
    .aggregate([
      {
        $match: {},
      },
      {
        $replaceRoot: {
          newRoot: {
            value: { $toString: '$_id' },
            label: '$name',
          },
        },
      },
    ]);

  return result;
};

export const userRegister = async ({ data, roleId }) => {
  if (!data || !roleId) {
    return;
  }

  const credentials = Realm.Credentials.anonymous();
  const test = await app.logIn(credentials);

  if (test) {
    const result = await app.currentUser.callFunction('register', {
      data,
      roleId,
    });

    if (app.currentUser) {
      app.currentUser.logOut();
    }
    return result;
  }
};

export const DeletePoster = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('posters').deleteOne({ _id: id });

  return result;
};

export const AddPoster = async ({ data, isActive, image, tagsIds, news }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }
  const tags = await getDBInstance(app)
    .collection('tags')
    .find({ _id: { $in: tagsIds } });

  // Extract the tag names from the retrieved tags
  const tagNames = tags.map((tag) => tag.name).join(', ');

  const result = await getDBInstance()
    .collection('posters')
    .insertOne({
      title: {
        en: data.title,
        ml: data.title,
      },
      description: {
        en: data.description,
        ml: data.description,
      },
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      tags: tagNames,
      tags_ids: tagsIds,
      news,
    });
  return result;
};

export const UpdatePoster = async ({ id, data, isActive, image, tagsIds, news }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const tags = await getDBInstance(app)
    .collection('tags')
    .find({ _id: { $in: tagsIds } });

  // Extract the tag names from the retrieved tags
  const tagNames = tags.map((tag) => tag.name).join(', ');

  const result = await getDBInstance()
    .collection('posters')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          title: {
            en: data.title,
            ml: data.title,
          },
          description: {
            en: data.description,
            ml: data.description,
          },
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          tags: tagNames,
          tags_ids: tagsIds,
          news,
        },
      },
    );

  return result;
};

export const getSearchPosters = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let query = {};
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('posters').count({});

  const result = await getDBInstance()
    .collection('posters')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags_ids',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getPosters = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let skip = (currentPage - 1) * pageSize;
  let query = {};

  if (search) {
    skip = 0;
    query = {
      $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('posters').count({});

  const result = await getDBInstance()
    .collection('posters')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags_ids',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOnePoster = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('posters')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

// export const getTags = async ({ search }) => {
//   if (!app || !app.currentUser) {
//     return;
//   }

//   const result = await getDBInstance()
//     .collection('tags')
//     .aggregate([
//       {
//         $match: {
//           $or: [{ name: { $regex: `^${search}`, $options: 'i' } }],
//         },
//       },
//       {
//         $replaceRoot: {
//           newRoot: {
//             value: { $toString: '$_id' },
//             label: '$name',
//           },
//         },
//       },
//       // {
//       //   $limit: 5000,
//       // },
//     ]);
//   console.log('result ===', result);
//   return result;
// };

// export const getTags = async ({ search, entity }) => {
//   if (!app || !app.currentUser) {
//     return null; // Return null or appropriate error response
//   }
//   const result = await getDBInstance(app)
//     .collection('news')
//     .aggregate([
//       {
//         $match: {
//           entity: entity === 'News' ? 'news' : 'videos', // Match the documents where 'entity' field matches the provided entity parameter
//         },
//       },
//       {
//         $addFields: {
//           // Prepare the field for lookup by mapping over the tags_Ids array and extracting the $oid
//           converted_tag_ids: {
//             $map: {
//               input: '$tags_Ids',
//               as: 'tag_id',
//               in: '$$tag_id',
//             },
//           },
//         },
//       },
//       {
//         $lookup: {
//           from: 'tags', // Assuming 'tags' is the correct collection name
//           localField: 'converted_tag_ids',
//           foreignField: '_id',
//           as: 'tag_details',
//         },
//       },
//       {
//         $unwind: {
//           path: '$tag_details',
//           preserveNullAndEmptyArrays: false, // Only keep documents that match the lookup
//         },
//       },
//       {
//         $match: {
//           'tag_details.name': { $regex: `^${search}`, $options: 'i' }, // Search case-insensitive from start of string
//         },
//       },
//       {
//         $replaceRoot: {
//           newRoot: {
//             value: { $toString: '$tag_details._id' }, // Convert ObjectId to string
//             label: '$tag_details.name', // Use the tag name
//           },
//         },
//       },
//     ]);
//   return result;
// };

export const getTags = async ({ search, entity }) => {
  if (!app || !app.currentUser) {
    throw new Error('User not authenticated');
  }

  const db = getDBInstance(app);
  const entityCondition = {
    entity: entity === 'News' ? 'news' : 'videos',
  };

  const searchCondition = {
    'tag_details.name': { $regex: `^${search}`, $options: 'i' },
  };

  const pipeline = [
    { $match: entityCondition },
    {
      $lookup: {
        from: 'tags',
        localField: 'tags_Ids',
        foreignField: '_id',
        as: 'tag_details',
      },
    },
    { $unwind: { path: '$tag_details', preserveNullAndEmptyArrays: false } },
    {
      $lookup: {
        from: 'trendingTags',
        localField: 'tag_details._id',
        foreignField: 'tagId',
        as: 'trending_tags',
      },
    },
    {
      $match: {
        trending_tags: {
          $not: {
            $elemMatch: {
              entity,
            },
          },
        },
      },
    },
    { $match: searchCondition },
    {
      $project: {
        value: { $toString: '$tag_details._id' },
        label: '$tag_details.name',
      },
    },
  ];

  try {
    const result = await db.collection('news').aggregate(pipeline);
    return result;
  } catch (error) {
    throw new Error('Failed to fetch tags');
  }
};

export const getDefaultTags = async (selectedTagIds) => {
  if (!app || !app.currentUser) {
    return;
  }

  const optionIds = selectedTagIds?.map((id) => new ObjectId(id));

  const result = await getDBInstance()
    .collection('tags')
    .aggregate([
      {
        $match: {
          _id: { $in: optionIds },
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: { $toString: '$_id' },
            label: '$name',
          },
        },
      },
    ]);

  return result;
};

export const DeleteTrendingTag = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('trendingTags').deleteOne({ _id: id });

  return result;
};

export const AddTrendingTag = async ({ items, entity }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!items) {
    return;
  }

  const db = getDBInstance();
  const collection = db.collection('trendingTags');

  const trending = await collection.aggregate([
    {
      $match: { entity },
    },
    {
      $lookup: {
        from: 'tags',
        localField: 'tagId',
        foreignField: '_id',
        as: 'getTag',
      },
    },
    { $unwind: '$getTag' },
    {
      $sort: {
        trendingOrderNumber: 1,
      },
    },
  ]);

  const itemsTagIds = items.map((item) => item.tagId.toString()); // Convert all item tagIds to string and store in an array for easy comparison.

  const nonMatchingTrendingTagIds = trending
    .filter((trendingItem) => !itemsTagIds.includes(trendingItem.tagId.toString())) // Filter out trending items whose tagIds are not in itemsTagIds.
    .map((filteredTrendingItem) => filteredTrendingItem.tagId); // Map the filtered trending items to their tagIds.

  await collection.deleteMany({
    tagId: { $in: nonMatchingTrendingTagIds },
    entity,
  });

  const updatePromises = items.map((item) => {
    return collection
      .findOne({ tagId: item.tagId, entity: item.entity })
      .then((foundItem) => {
        if (foundItem) {
          // If the item is found, update it
          return collection.updateOne({ tagId: item.tagId }, { $set: item }, { upsert: true });
        }
        // If the item is not found, insert a new one
        return collection.insertOne({
          tagId: item.tagId,
          dateCreated: new Date(),
          entity: item.entity,
          trendingOrderNumber: item.trendingOrderNumber,
        });
      })
      .catch((error) => {
        throw error; // Rethrow to handle in Promise.all
      });
  });

  //   return new Promise((resolve, reject) => {
  //     trending.forEach((trendingItem) => {
  //       if (item.tagId.toString() === trendingItem.tagId.toString() && item.entity === trendingItem.entity) {
  //         collection.updateOne({ tagId: item.tagId }, { $set: item }, { upsert: true }).then(resolve).catch(reject);
  //       } else {
  //         collection
  //           .insertOne({
  //             tagId: item.tagId,
  //             dateCreated: new Date(),
  //             entity: item.entity,
  //             trendingOrderNumber: item.trendingOrderNumber,
  //           })
  //           .then(resolve)
  //           .catch(reject);
  //       }
  //     });
  //   });
  // });

  // Promise.all(updateOperations)
  //   .then(() => {
  //     console.log('All updates and inserts have been completed.');
  //   })
  //   .catch((error) => {
  //     console.error('An error occurred during updates and inserts:', error);
  //   });

  const results = await Promise.all(updatePromises);
  return results;
};

export const UpdateTrendingTag = async ({ id, data, tagId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('trendingTags')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          tagId,
          trendingOrderNumber: data.orderNumber,
          dateModified: new Date(),
        },
      },
    );

  return result;
};

export const getTrendingTags = async ({ entity }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('trendingTags')
    .aggregate([
      {
        $match: { entity },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tagId',
          foreignField: '_id',
          as: 'getTag',
        },
      },
      { $unwind: '$getTag' },
      {
        $sort: {
          trendingOrderNumber: 1,
        },
      },
    ]);

  // console.log('result', result);
  return result;
};

export const getOneTrendingTag = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('trendingTags')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tagId',
          foreignField: '_id',
          as: 'getTag',
        },
      },
      { $unwind: '$getTag' },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};
export const getAllTags = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let skip = (currentPage - 1) * pageSize;
  let query = {};

  if (search) {
    skip = 0;
    query = {
      $or: [{ name: { $regex: search, $options: 'i' } }],
    };
  }

  const result = await getDBInstance()
    .collection('tags')
    .aggregate([
      {
        $match: query,
      },
      { $skip: skip },
      {
        $project: {
          _id: 1,
          remoteId: 1,
          name: 1,
          isActive: 1,
          remoteParentId: 1,
        },
      },
      { $sort: { _id: -1 } },
    ]);
  return result;
};

export const getAllCategories = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let skip = (currentPage - 1) * pageSize;
  let query = {};

  if (search) {
    skip = 0;
    query = {
      $or: [{ 'name.en': { $regex: search, $options: 'i' } }, { 'name.ml': { $regex: search, $options: 'i' } }],
    };
  }
  const result = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: query,
      },
      { $skip: skip },
      {
        $project: {
          _id: 1,
          remoteId: 1,
          name: 1,
          isActive: 1,
          remoteParentId: 1,
        },
      },
      { $sort: { _id: -1 } },
    ]);
  return result;
};

export const getTrendingTagIdExist = async ({ tagId, isEdit, isEditTagId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof tagId === 'string') {
    tagId = new ObjectId(tagId);
  }
  if (isEdit && tagId?.toString() === isEditTagId?.toString()) {
    return false;
  }

  const result = await getDBInstance()
    .collection('trendingTags')
    .aggregate([
      {
        $match: { tagId },
      },
    ]);

  return result?.length > 0 ? true : false;
};

export const getTrendingOrderNumberExist = async ({ orderNumber, isEdit, isEditOrderNumber }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (isEdit && orderNumber === isEditOrderNumber) {
    return false;
  }
  const result = await getDBInstance()
    .collection('trendingTags')
    .aggregate([
      {
        $match: { trendingOrderNumber: orderNumber },
      },
    ]);

  return result?.length > 0 ? true : false;
};

export const DeletePodcast = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('podcasts').deleteOne({ _id: id });

  return result;
};

export const AddPodcast = async ({ data, isActive, image, showId, tagsIds }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data && !showId) {
    return;
  }
  const tags = await getDBInstance(app)
    .collection('tags')
    .find({ _id: { $in: tagsIds } });

  // Extract the tag names from the retrieved tags
  const tagNames = tags.map((tag) => tag.name).join(', ');

  const result = await getDBInstance()
    .collection('podcasts')
    .insertOne({
      title: { en: data.title, ml: data.title },
      podcastUrl: data.podcastUrl,
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      showId,
      tags: tagNames,
      tags_ids: tagsIds,
    });
  return result;
};

export const UpdatePodcast = async ({ id, data, isActive, image, showId, tagsIds }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id && !data && !showId) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }
  const tags = await getDBInstance(app)
    .collection('tags')
    .find({ _id: { $in: tagsIds } });

  // Extract the tag names from the retrieved tags
  const tagNames = tags.map((tag) => tag.name).join(', ');

  const result = await getDBInstance()
    .collection('podcasts')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          title: { en: data.title, ml: data.title },
          podcastUrl: data.podcastUrl,
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          showId,
          tags: tagNames,
          tags_ids: tagsIds,
        },
      },
    );

  return result;
};

export const getSearchPodcasts = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let query = {};
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('podcasts').count({});

  const result = await getDBInstance()
    .collection('podcasts')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          isoDate: -1,
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags_ids',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      {
        $lookup: {
          from: 'shows',
          localField: 'showId',
          foreignField: '_id',
          as: 'getShow',
        },
      },
      { $unwind: '$getShow' },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getPodcasts = async ({ currentPage = 1, pageSize = 10, search, showId }) => {
  if (!app || !app.currentUser) {
    return;
  }

  let query = {};
  let skip = (currentPage - 1) * pageSize;

  if (typeof showId === 'string') {
    showId = new ObjectId(showId);
  }

  if (showId) {
    query.showId = showId;
  }

  if (search) {
    skip = 0;
    query = {
      $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('podcasts').count({});

  const result = await getDBInstance()
    .collection('podcasts')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          isoDate: -1,
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags_ids',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      {
        $lookup: {
          from: 'shows',
          localField: 'showId',
          foreignField: '_id',
          as: 'getShow',
        },
      },
      { $unwind: '$getShow' },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOnePodcast = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('podcasts')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $lookup: {
          from: 'shows',
          localField: 'showId',
          foreignField: '_id',
          as: 'getShow',
        },
      },
      { $unwind: '$getShow' },
    ]);

  return result[0];
};

export const AddCms = async ({ data, cmsData }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  const slug = await generateSlug({ title: data.title });

  const result = await getDBInstance().collection('cmsPages').insertOne({
    title: data.title,
    slug,
    description: cmsData,
    dateCreated: new Date(),
    dateModified: null,
  });
  return result;
};

export const UpdateCms = async ({ id, data, cmsData }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const slug = await generateSlug({ title: data.title });
  const result = await getDBInstance()
    .collection('cmsPages')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          title: data.title,
          slug,
          description: cmsData,
          dateModified: new Date(),
        },
      },
    );

  return result;
};

export const getCms = async ({ search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let query = {};

  if (search) {
    query = {
      $or: [{ title: { $regex: search, $options: 'i' } }],
    };
  }

  const result = await getDBInstance()
    .collection('cmsPages')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result;
};

export const getOneCms = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('cmsPages')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const DeleteCms = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('cmsPages').deleteOne({ _id: id });

  return result;
};

export const fetchLatestNews = async (remoteId) => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchNews', { newsId: remoteId });
  return result;
};

export const fetchLatestReels = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchReels', {});
  return result;
};

export const fetchLatestTags = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchTags', {});
  return result;
};

export const FetchLatestPodcast = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchPodcasts', {});
  return result;
};

export const FetchLatestReel = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchReels', {});
  return result;
};

export const FetchLatestCategories = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchCategories', {});
  return result;
};
export const FetchLatestShowsCategories = async () => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchCategories', { categoryId: 9 });
  return result;
};
export const FetchOneShow = async (remoteId) => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchNews', { categoryIds: remoteId });
  return result;
};
export const FetchOneTag = async (name) => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchTags', { tagName: name });
  return result;
};
export const FetchOneCategory = async (id) => {
  if (!app || !app.currentUser) {
    return;
  }
  const mUser = await middlewareLogin();
  const result = await mUser?.callFunction('fetchCategories', { categoryId: id });

  return result;
};

export const DeleteShows = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('shows').deleteOne({ _id: id });

  return result;
};

export const AddShows = async ({ data, isActive, image }) => {
  if (!app || !app.currentUser) {
    return;
  }

  if (!data) {
    return;
  }

  const result = await getDBInstance()
    .collection('shows')
    .insertOne({
      title: { en: data.title, ml: data.title },
      description: { en: data.description, ml: data.description },
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      feedUrl: data.feedUrl,
      author: null,
      copyright: null,
      creator: null,
      generator: null,
      language: null,
      itunes: {},
      lastBuildDate: null,
      link: null,
      paginationLinks: [],
    });
  return result;
};

export const UpdateShows = async ({ id, data, isActive, image }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('shows')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          title: { en: data.title, ml: data.title },
          description: { en: data.description, ml: data.description },
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          feedUrl: data.feedUrl,
        },
      },
    );

  return result;
};

export const getShows = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let query = {};
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('shows').count({});

  const result = await getDBInstance()
    .collection('shows')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneShow = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('shows')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getShowsSelect = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('shows')
    .aggregate([
      {
        $match: { isActive: true },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: { $toString: '$_id' },
            label: '$title.en',
          },
        },
      },
    ]);

  return result;
};

export const AddMaintenance = async ({ iosMaintenanceMode, androidMaintenanceMode, data, fullAppMaintenanceMode }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance().collection('maintenance').insertOne({
    name: 'maintenanceMode',
    androidMinimumVersion: data?.androidMinimumVersion,
    iosMinimumVersion: data?.iosMinimumVersion,
    maintenanceModeIos: iosMaintenanceMode,
    maintenanceModeAndroid: androidMaintenanceMode,
    fullAppMaintenanceMode,
    dateCreated: new Date(),
    dateModified: null,
  });
  return result;
};

export const UpdateMaintenance = async ({
  id,
  iosMaintenanceMode,
  androidMaintenanceMode,
  data,
  fullAppMaintenanceMode,
}) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          androidMinimumVersion: data?.androidMinimumVersion,
          iosMinimumVersion: data?.iosMinimumVersion,
          maintenanceModeIos: iosMaintenanceMode,
          maintenanceModeAndroid: androidMaintenanceMode,
          fullAppMaintenanceMode,
          dateModified: new Date(),
        },
      },
    );

  return result;
};

export const getOneMaintenance = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .aggregate([
      {
        $match: { name: 'maintenanceMode' },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const AddAds = async ({ adsConfig }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance().collection('maintenance').insertOne({
    name: 'advertisement',
    fullAdsEnabled: adsConfig.fullAdsEnabled,
    homeAdsEnabled: adsConfig.homeAdsEnabled,
    videoAdsEnabled: adsConfig.videoAdsEnabled,
    dateCreated: new Date(),
    dateModified: null,
  });
  return result;
};

export const UpdateAds = async ({ id, adsConfig }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          fullAdsEnabled: adsConfig.fullAdsEnabled,
          homeAdsEnabled: adsConfig.homeAdsEnabled,
          videoAdsEnabled: adsConfig.videoAdsEnabled,
          dateModified: new Date(),
        },
      },
    );

  return result;
};

export const getOneAds = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .aggregate([
      {
        $match: { name: 'advertisement' },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getAppCustomers = async ({ currentPage = 1, pageSize = 10, dateRange = [null, null] }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = (currentPage - 1) * pageSize;

  const dateFilter = {};
  if (dateRange[0] && dateRange[1]) {
    dateFilter.dateCreated = {
      $gte: new Date(dateRange[0]),
      $lte: new Date(dateRange[1]),
    };
  }

  const matchStage = Object.keys(dateFilter).length ? dateFilter : {};

  const totalDocumentCount = await getDBInstance().collection('customers').count(matchStage);

  const result = await getDBInstance()
    .collection('customers')
    .aggregate([
      { $match: matchStage },
      {
        $sort: {
          _id: -1,
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};
export const AddEvent = async ({ isActive, image, categories, tags, data }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (isActive) {
    await getDBInstance()
      .collection('events')
      .updateMany({}, { $set: { isActive: false } });
  }
  const result = await getDBInstance()
    .collection('events')
    .insertOne({
      isActive,
      image: image ? image : {},
      dateCreated: new Date(),
      dateModified: null,
      categories,
      tags,
      name: { en: data.name, ml: data.name },
    });
  return result;
};

export const UpdateEvent = async ({ id, isActive, image, categories, tags, data }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }
  if (isActive) {
    await getDBInstance()
      .collection('events')
      .updateMany({ _id: { $ne: id } }, { $set: { isActive: false } });
  }

  const result = await getDBInstance()
    .collection('events')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive,
          image: image ? image : {},
          dateModified: new Date(),
          categories,
          tags,
          name: { en: data.name, ml: data.name },
        },
      },
    );

  return result;
};

export const DeleteEvent = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('events').deleteOne({ _id: id });

  return result;
};

export const getEvents = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  let query = {};
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $or: [{ 'name.en': { $regex: search, $options: 'i' } }, { 'name.ml': { $regex: search, $options: 'i' } }],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('events').count({});

  const result = await getDBInstance()
    .collection('events')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          _id: -1,
        },
      },
      {
        $lookup: {
          from: 'categories',
          localField: 'categories',
          foreignField: '_id',
          as: 'getCategories',
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const getOneEvent = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('events')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $lookup: {
          from: 'categories',
          localField: 'categories',
          foreignField: '_id',
          as: 'getCategories',
        },
      },
      {
        $lookup: {
          from: 'tags',
          localField: 'tags',
          foreignField: '_id',
          as: 'getTags',
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getCategories = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: [
              'categories',
              // "subCategories"
            ],
          },
        },
      },
      {
        $replaceRoot: {
          newRoot: {
            value: { $toString: '$_id' },
            label: '$name.en',
          },
        },
      },
    ]);

  return result;
};

export const getAppShows = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = (currentPage - 1) * pageSize;
  const programsCategoryId = new ObjectId(envConfig.PROGRAM_CATEGORY_ID);
  const countResult = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: [
              // 'categories',
              'subCategories',
            ],
          },
          parentId: programsCategoryId,
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $count: 'total',
      },
    ]);
  // Convert to array to access the count result

  // Extract the count from the aggregate result
  const totalDocumentCount = countResult.length > 0 ? countResult[0].total : 0;

  const result = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: [
              // 'categories',
              'subCategories',
            ],
          },
          parentId: programsCategoryId,
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $sort: {
          showsInApp: -1, // This ensures that items with showsInApp=true come first
          showInAppSortOrder: 1, // This sorts those items by their sortOrder ascendingly
        },
      },
      {
        $lookup: {
          from: 'categories',
          localField: 'parentId',
          foreignField: '_id',
          as: 'getCategory',
        },
      },
      { $unwind: '$getCategory' },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};
export const UpdateAppShow = async ({ id, showsInApp }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('categories')
    .updateOne(
      {
        _id: id,
        entity: {
          $in: ['subCategories'],
        },
      },
      {
        $set: {
          showsInApp,
        },
      },
    );

  return result;
};

export const UpdateNews = async ({ id, news }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('news')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: news,
        },
      },
    );
  return result;
};

export const UpdateCategory = async ({ id, active }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('categories')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: active,
        },
      },
    );
  return result;
};

export const AddLiveTv = async ({ liveTvConfig, data }) => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .insertOne({
      name: 'liveTv',
      title: {
        en: data.liveTvTitle,
        ml: data.liveTvTitle,
      },
      liveTvEnable: liveTvConfig.liveTvEnable,
      dateCreated: new Date(),
      dateModified: null,
      ytLiveId: data.liveTvId,
      liveUrl: data.liveTvUrl,
      titleHome: {
        en: data.homeLiveTvTitle,
        ml: data.homeLiveTvTitle,
      },
      ytLiveIdHome: data.homeLiveTvId,
      liveUrlHome: data.homeLiveTvUrl,
    });
  return result;
};

export const UpdateLiveTv = async ({ id, liveTvConfig, data }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          title: {
            en: data.liveTvTitle,
            ml: data.liveTvTitle,
          },
          liveTvEnable: liveTvConfig.liveTvEnable,
          dateModified: new Date(),
          ytLiveId: data.liveTvId,
          liveUrl: data.liveTvUrl,
          titleHome: {
            en: data.homeLiveTvTitle,
            ml: data.homeLiveTvTitle,
          },
          ytLiveIdHome: data.homeLiveTvId,
          liveUrlHome: data.homeLiveTvUrl,
        },
      },
    );

  return result;
};

export const getOneLiveTv = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('maintenance')
    .aggregate([
      {
        $match: { name: 'liveTv' },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};
export const getAppMoreCategories = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = (currentPage - 1) * pageSize;
  const countResult = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: ['categories', 'subCategories'],
          },
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $count: 'total',
      },
    ]);
  // Convert to array to access the count result

  // Extract the count from the aggregate result
  const totalDocumentCount = countResult.length > 0 ? countResult[0].total : 0;

  const result = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: ['categories', 'subCategories'],
          },
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $sort: {
          isMoreCategoriesInApp: -1,
          moreCategoriesInAppSortOrder: 1,
          _id: 1,
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const UpdateMoreCategories = async ({ id, isMoreCategoriesInApp }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('categories').updateOne(
    {
      _id: id,
    },
    {
      $set: {
        isMoreCategoriesInApp,
      },
    },
  );

  return result;
};

export const getAppPersonalizedCategories = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const skip = (currentPage - 1) * pageSize;
  const countResult = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: ['categories', 'subCategories'],
          },
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $count: 'total',
      },
    ]);
  // Convert to array to access the count result

  // Extract the count from the aggregate result
  const totalDocumentCount = countResult.length > 0 ? countResult[0].total : 0;

  const result = await getDBInstance()
    .collection('categories')
    .aggregate([
      {
        $match: {
          entity: {
            $in: ['categories', 'subCategories'],
          },
          $or: [{ 'name.en': { $regex: search, $options: 'i' } }],
        },
      },
      {
        $sort: {
          isPersonalizedCategoriesInApp: -1,
          personalizedCategoriesInAppSortOrder: 1,
          _id: 1,
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);

  return { data: result, totalDocumentCount };
};

export const UpdatePersonalizedCategories = async ({ id, isPersonalizedCategoriesInApp }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance().collection('categories').updateOne(
    {
      _id: id,
    },
    {
      $set: {
        isPersonalizedCategoriesInApp,
      },
    },
  );

  return result;
};
export const getNews = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  let query = {
    dateCreated: { $gte: sixMonthsAgo },
  };
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $and: [
        { dateCreated: { $gte: sixMonthsAgo } },
        {
          $or: [
            { 'heading.en': { $regex: search, $options: 'i' } },
            { 'heading.ml': { $regex: search, $options: 'i' } },
          ],
        },
      ],
    };
  }

  const result = await getDBInstance(app)
    .collection('news')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          dateCreated: -1,
        },
      },
      { $skip: skip },
      { $limit: 50 },
      {
        $project: {
          _id: 1,
          remoteId: 1,
          description: 1,
          heading: 1,
          media: 1,
          thumbUrl: 1,
          entity: 1,
          isActive: 1,
        },
      },
    ]);

  return { data: result };
};

export const sendPushNotificationForNews = async ({ id, entity }) => {
  const result = await app.currentUser.callFunction('pushNotification', {
    // functionName: 'sendAllUserNotification',
    functionName: 'sendMulticastNotification',
    params: { id, entity },
  });
  return result;
};
export const getUploadUrl = async () => {
  const result = await app.currentUser.callFunction('Cloudflare');
  return result;
};

export const getNewsPosters = async ({ currentPage = 1, pageSize = 10, search, newsId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof newsId === 'string') {
    newsId = new ObjectId(newsId);
  }
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  let query = {
    dateCreated: { $gte: sixMonthsAgo },
  };
  let skip = (currentPage - 1) * pageSize;
  if (search) {
    skip = 0;
    query = {
      $and: [
        { dateCreated: { $gte: sixMonthsAgo } },
        {
          $or: [
            { 'heading.en': { $regex: search, $options: 'i' } },
            { 'heading.ml': { $regex: search, $options: 'i' } },
          ],
        },
      ],
    };
  }
  let result1 = [];
  result1 = await getDBInstance(app)
    .collection('news')
    .aggregate([
      {
        $match: {
          _id: newsId,
        },
      },
      {
        $project: {
          _id: 1,
          remoteId: 1,
          description: 1,
          heading: 1,
          media: 1,
          entity: 1,
          thumbUrl: 1,
        },
      },
    ]);

  const result = await getDBInstance(app)
    .collection('news')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          dateCreated: -1,
        },
      },
      { $skip: skip },
      { $limit: 50 },
      {
        $project: {
          _id: 1,
          remoteId: 1,
          description: 1,
          heading: 1,
          media: 1,
          entity: 1,
          thumbUrl: 1,
        },
      },
    ]);
  const mergeResultsUniqueById = (result1, result2) => {
    const combined = [...result1, ...result2];
    const uniqueMap = new Map();

    combined.forEach((item) => {
      uniqueMap.set(item?._id.toString(), item);
    });

    return Array.from(uniqueMap.values());
  };

  const mergedResults = mergeResultsUniqueById(result1, result);

  return { data: mergedResults };
};

export const UpdateSortOrderAppShow = async ({ sortedIds }) => {
  if (!app || !app.currentUser) {
    return;
  }

  try {
    const updatePromises = [];

    for (let index = 0; index < sortedIds.length; index += 1) {
      const id = sortedIds[index];
      const objectId = typeof id === 'string' ? new ObjectId(id) : id;
      updatePromises.push(
        getDBInstance()
          .collection('categories')
          .updateOne({ _id: objectId, showsInApp: true }, { $set: { showInAppSortOrder: index + 1 } }),
      );
    }

    await Promise.all(updatePromises);
    return { message: 'Sort order updated successfully for all items' };
  } catch (error) {
    return { error: 'Failed to update sort order', details: error };
  }
};

export const UpdateSortOrderMoreCategories = async ({ sortedIds }) => {
  if (!app || !app.currentUser) {
    return;
  }

  try {
    const updatePromises = [];

    for (let index = 0; index < sortedIds.length; index += 1) {
      const id = sortedIds[index];
      const objectId = typeof id === 'string' ? new ObjectId(id) : id;
      updatePromises.push(
        getDBInstance()
          .collection('categories')
          .updateOne(
            { _id: objectId, isMoreCategoriesInApp: true },
            { $set: { moreCategoriesInAppSortOrder: index + 1 } },
          ),
      );
    }

    await Promise.all(updatePromises);
    return { message: 'Sort order updated successfully for all items' };
  } catch (error) {
    return { error: 'Failed to update sort order', details: error };
  }
};

export const UpdateSortOrderPersonalizedCategories = async ({ sortedIds }) => {
  if (!app || !app.currentUser) {
    return;
  }

  try {
    const updatePromises = [];

    for (let index = 0; index < sortedIds.length; index += 1) {
      const id = sortedIds[index];
      const objectId = typeof id === 'string' ? new ObjectId(id) : id;
      updatePromises.push(
        getDBInstance()
          .collection('categories')
          .updateOne(
            { _id: objectId, isPersonalizedCategoriesInApp: true },
            { $set: { personalizedCategoriesInAppSortOrder: index + 1 } },
          ),
      );
    }

    await Promise.all(updatePromises);
    return { message: 'Sort order updated successfully for all items' };
  } catch (error) {
    return { error: 'Failed to update sort order', details: error };
  }
};

export const getUserRegisterRoles = async () => {
  if (!app || !app.currentUser) {
    return;
  }

  const result = await getDBInstance()
    .collection('userRoles')
    .aggregate([
      {
        $match: {},
      },
      {
        $replaceRoot: {
          newRoot: {
            value: { $toString: '$_id' },
            label: '$name',
          },
        },
      },
    ]);

  return result;
};

export const userAddWithRole = async ({ data, roleId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!data || !roleId) {
    return;
  }
  if (typeof roleId === 'string') {
    roleId = new ObjectId(roleId);
  }

  const result = await getDBInstance().collection('users').insertOne({
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    number: data.number,
    createdAt: new Date(),
    updatedAt: null,
    isVerified: false,
    roleId,
  });
  return result;
};

export const userUpdateWithRole = async ({ id, data, roleId }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id || !data || !roleId) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  if (typeof roleId === 'string') {
    roleId = new ObjectId(roleId);
  }
  const specificRoleId = new ObjectId('661d7c37952ae5895d13ea85');
  const findResult = await getDBInstance().collection('users').find({ roleId: specificRoleId });
  if (findResult.length === 1) {
    const Result = await getDBInstance().collection('users').findOne({ _id: id });
    if (Result.roleId.toString() === specificRoleId.toString()) {
      return false;
    }
    await getDBInstance()
      .collection('users')
      .updateOne(
        {
          _id: id,
        },
        {
          $set: {
            firstName: data.firstName,
            lastName: data.lastName,
            number: data.number,
            updatedAt: new Date(),
            roleId,
          },
        },
      );

    return true;
  }
  await getDBInstance()
    .collection('users')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          firstName: data.firstName,
          lastName: data.lastName,
          number: data.number,
          updatedAt: new Date(),
          roleId,
        },
      },
    );

  return true;
};

export const DeleteUser = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const specificRoleId = new ObjectId('661d7c37952ae5895d13ea85');
  const findResult = await getDBInstance().collection('users').find({ roleId: specificRoleId });
  if (findResult.length === 1) {
    const Result = await getDBInstance().collection('users').findOne({ _id: id });
    if (Result.roleId.toString() === specificRoleId.toString()) {
      return false;
    }
    await getDBInstance().collection('users').deleteOne({ _id: id });
    return true;
  }
  await getDBInstance().collection('users').deleteOne({ _id: id });
  return true;
};

export const getOneUser = async ({ id }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('users')
    .aggregate([
      {
        $match: { _id: id },
      },
      {
        $lookup: {
          from: 'userRoles',
          localField: 'roleId',
          foreignField: '_id',
          as: 'getRole',
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result[0];
};

export const getUsers = async ({ search }) => {
  if (!app || !app.currentUser) {
    return;
  }

  let query = {};

  if (search) {
    query = {
      $or: [{ email: { $regex: search, $options: 'i' } }],
    };
  }

  const result = await getDBInstance()
    .collection('users')
    .aggregate([
      {
        $match: query,
      },
      {
        $lookup: {
          from: 'userRoles',
          localField: 'roleId',
          foreignField: '_id',
          as: 'getRole',
        },
      },
      {
        $sort: {
          _id: -1,
        },
      },
    ]);

  return result;
};

export const sendEmailAfterLogin = async ({ toEmail }) => {
  if (!toEmail) {
    return;
  }

  const result = await app.currentUser.callFunction('reSendEmail', {
    toEmail,
  });
  return result;
};

export const verifyOTP = async ({ email, submittedOtp }) => {
  if (!email || !submittedOtp) {
    return;
  }

  const result = await app.currentUser.callFunction('verifyOTP', { email, submittedOtp });
  return result;
};

export const getReels = async ({ currentPage = 1, pageSize = 10, search }) => {
  if (!app || !app.currentUser) {
    return;
  }
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  let query = {
    dateCreated: { $gte: sixMonthsAgo },
  };
  let skip = (currentPage - 1) * pageSize;

  if (search) {
    skip = 0;
    query = {
      $and: [
        { dateCreated: { $gte: sixMonthsAgo } },
        {
          $or: [{ 'title.en': { $regex: search, $options: 'i' } }, { 'title.ml': { $regex: search, $options: 'i' } }],
        },
      ],
    };
  }

  const totalDocumentCount = await getDBInstance().collection('reels').count({});

  const result = await getDBInstance()
    .collection('reels')
    .aggregate([
      {
        $match: query,
      },
      {
        $sort: {
          dateCreated: -1,
        },
      },
      { $skip: skip },
      { $limit: pageSize },
    ]);
  return { data: result, totalDocumentCount };
};
export const UpdateReels = async ({ id, reels }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('reels')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: reels,
        },
      },
    );
  return result;
};
export const UpdatePodcastStatus = async ({ id, status }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('podcasts')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: status,
        },
      },
    );
  return result;
};
export const UpdateShowStatus = async ({ id, status }) => {
  if (!app || !app.currentUser) {
    return;
  }
  if (!id) {
    return;
  }
  if (typeof id === 'string') {
    id = new ObjectId(id);
  }

  const result = await getDBInstance()
    .collection('shows')
    .updateOne(
      {
        _id: id,
      },
      {
        $set: {
          isActive: status,
        },
      },
    );
  return result;
};
